import { Link } from "gatsby"
import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import FedRAMP from "../../images/fedRAMP.png"

const DomeTermsOfService: React.FC = () => {
  return (
    <main className={"w-full"}>
      <section className="flex justify-center bg-blueShade1">
        <article className="flex flex-col items-center max-w-700 xl-mobile:px-8">
          <TextTypography
            className="text-4xl sm:text-6xl my-12"
            fontStyle={FontStyle.BOLD}
          >
            Dome Compass, Inc.
          </TextTypography>

          <div className="flex flex-col items-center mb-6">
            <img src={FedRAMP} alt="fedRAMP" className="mb-3 rounded-16 w-40" />
            <TextTypography
              className="text-xl font-semibold"
              color={TextColor.SECONDARY}
            >
              FedRAMP Compliant
            </TextTypography>
          </div>

          <TextTypography className="mb-6 font-semibold  text-3xl sm:text-4xl">
            Terms and Conditions
          </TextTypography>
          <TextTypography className="mb-8 font-bold text-xl">
            Last updated October 12, 2022
          </TextTypography>

          <TextTypography className="mb-8 text-xl font-normal">
            Welcome to the Dome website and related interactive features,
            services, applications or downloads (collectively, the “Site”) that
            is owned by Dome Compass, Inc., (“Company,” “we” and “us”). BY USING
            OUR SITE YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THESE TERMS
            AND CONDITIONS (“TERMS”) AND OUR PRIVACY POLICY. IF YOU DO NOT AGREE
            TO THESE TERMS, YOU MAY NOT USE THIS SITE. If we make material
            changes to this policy, we will notify you in advance BY POSTING
            NOTICE OF THE CHANGES ON OUR SERVICES. YOUR CONTINUED USE OF OUR
            SITE OR ANY AFFILIATE SITE FOLLOWING THE POSTING OF CHANGES TO THESE
            TERMS WILL MEAN YOU ACCEPT THOSE CHANGES.
          </TextTypography>

          <TextTypography className="font-bold mb-8  text-3xl sm:text-4xl font-a">
            Assistance
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            If you have any questions or comments, we invite you to contact our
            Customer Service Department by email at{" "}
            <a
              href={`mailto:privacy@domecompass.com`}
              target={"_blank"}
              rel={"noreferrer noopener"}
              className={"text-xl text-primaryDefault"}
            >
              privacy@domecompass.com.
            </a>
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Copyright, Trademark and Ownership
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            All of the content displayed on the Site, including without
            limitation text, graphics, photographs, images, moving images,
            sound, and illustrations (&ldquo;Content&rdquo;), is owned by
            Company, its licensors, agents or its Content providers. All
            elements of the Site, including, without without limitation, the
            Site’s general design, Company’s trademarks, service marks, trade
            names (including Company’s name, logos, the Site name, and the Site
            design), and other Content, are protected by trade dress, copyright,
            moral rights, trademark and other laws relating to intellectual
            property rights. The Site may only be used for the intended purpose
            for which it is which it is being made available. Except as may be
            otherwise indicated on the Site, you are view, play, print and
            download documents, audio and video found on our Site for personal,
            informational, and non-commercial purposes only. You may not use,
            copy, reproduce, republish, upload, post, transmit, distribute, or
            modify the Content or Company’s trademarks in any way, including in
            advertising or publicity pertaining to distribution of materials on
            the Site, without Company&apos;s prior written consent. The use of
            Company trademarks on any other Site is not allowed. Company
            prohibits the use of Company trademarks as a &ldquo;hot&rdquo; link
            on or to any other Site unless establishment of such a link is
            approved in advance. To make such a request, please send an email
            with a detailed description of the request to{" "}
            <a
              href={`mailto:privacy@domecompass.com`}
              target={"_blank"}
              rel={"noreferrer noopener"}
              className={"text-xl text-primaryDefault"}
            >
              privacy@domecompass.com.
            </a>
            . The Site, its Content and all related rights shall remain the
            exclusive property of Company or its licensors unless otherwise
            expressly agreed. You will not remove any copyright, trademark or
            other proprietary notices from material found on the Site.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Unsolicited Submissions Policy
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Company is pleased to hear from its loyal users and welcomes your
            comments regarding Company. Except where Company specifically
            requests comments or submissions, Company does not accept or
            consider creative ideas, suggestions, or materials other than those
            it has specifically requested. This policy is designed to avoid
            misunderstandings if projects developed by Company&apos;s
            professional staff seem to others to be similar to their own
            creative work. Accordingly, Company requests that you not submit any
            creative ideas, suggestions, or materials except where specifically
            requested or solicited. If you do send us any unsolicited
            suggestions, you agree not to assert any ownership right of any kind
            in against Company (including, but not limited to copyright,
            trademark, unfair competition, moral rights, or implied contract),
            you hereby grant Company a nonexclusive, perpetual, worldwide
            license to use the unsolicited submission in every media and for
            every purpose now known or hereinafter discovered and you waive the
            right to receive any financial or other consideration in connection
            with such unsolicited submission including, but not limited to,
            credit. You release Company (and our officers, directors, agents,
            subsidiaries, joint ventures and employees) from claims, demands and
            damages (actual and consequential) of every kind and nature, known
            and unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way connected with your unsolicited
            submissions, including, without limitation, all claims for theft of
            ideas or copyright infringement.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            User Content
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Company encourages members of the public to submit user-published
            content or user content (e.g., postings to groups, participation in
            communities, videos, tips, etc.) to Company for consideration in
            connection with the Site (“User Content”). User Content remains the
            intellectual property of the individual user. By posting content on
            our Site, you expressly grant Company a non-exclusive, perpetual,
            irrevocable, royalty-free, fully paid-up worldwide, fully
            sub-licensable right to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, distribute, transmit,
            perform and display such content and your name, voice, and likeness
            as contained in your User Content, in whole or in part, and in any
            form throughout the world in any media or technology, whether now
            known or hereafter discovered, including all promotion, advertising,
            marketing, merchandising, publicity and any other ancillary uses
            thereof, and including the unfettered right to sublicense such
            rights, in perpetuity throughout the universe. All User Content that
            is publicly posted is deemed non-confidential and Company shall be
            under no obligation to maintain the confidentiality of any
            information, in whatever form, contained in any User Content. To
            submit a request to have content that you have posted removed,
            submit a request to{" "}
            <a
              href={`mailto:privacy@domecompass.com`}
              target={"_blank"}
              rel={"noreferrer noopener"}
              className={"text-xl text-primaryDefault"}
            >
              privacy@domecompass.com.
            </a>
            .
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            User Content does not represent the views of Company or any
            individual associated with Company, and we do not control the User
            Content. In no event shall you represent or suggest, directly or
            indirectly, Company’s endorsement of User Content. Company does not
            vouch for the accuracy or credibility of any User Content on our
            Site, and does not take any responsibility or assume any liability
            for any actions you may take as a result of reading User Content on
            our Site. Through your use of the Site, you may be exposed to
            content that you may find offensive, objectionable, harmful,
            inaccurate or deceptive.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Inappropriate User Submissions
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Company does not encourage and strongly discourages any User Content
            that results from any activity that: (i) may create a risk of harm,
            loss, injury, emotional distress, death, disability, disfigurement,
            or illness to you, to any other person, or to any animal; (ii) may
            create a risk of any loss or damage to any person or property; or
            (iii) may constitute a crime or create civil liability. You agree
            that you have not and will not engage in any of the foregoing
            activities in connection with producing your submission. Without
            limiting the foregoing, you agree that in conjunction with your
            submission, you will not inflict emotional distress on other people,
            will not humiliate other people (publicly or otherwise), will not
            assault or threaten other people, will not enter onto private
            property without permission, and will not otherwise engage in any
            activity that may result in injury, death, property damage, or
            liability of any kind. Company may reject or remove any submissions
            that Company believes, in its sole discretion, include any
            inappropriate or prohibited material.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            DIGITAL MILLENNIUM COPYRIGHT ACT (“DMCA”) NOTICE
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Third party materials that we do not own or control may be
            transmitted, stored, accessed or otherwise made available using the
            Site. Company has adopted a policy that provides for the removal of
            any content or the suspension of any user that is found to have
            repeatedly infringed on the copyright of a third party. If you
            believe any material available via the Site infringes a copyright,
            you should notify us using the notice procedure for claimed
            infringement under the DMCA (17 U.S.C. § 512). Your infringement
            notice should be sent to:
          </TextTypography>

          <TextTypography className="font-normal mb-6 text-xl">
            Dome Compass, Inc. Attn: Legal 700 Pennsylvania Ave SE Washington DC
            20003
          </TextTypography>

          <TextTypography className="font-normal mb-6 text-xl">
            Please make sure that you specifically identify the copyrighted work
            that you claim has been infringed by providing specific URL(s) and a
            precise description of where the copyrighted material is located on
            the page(s).
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            We may give notice to our users of any infringement notice by means
            of a general notice on any of our Sites, electronic mail to a
            user&apos;s e-mail address in our records, or by written
            communication sent to a user&apos;s last known physical address in
            our records. If you receive an infringement notice, you may provide
            counter-notification in writing. Please be advised that if you
            submit a counter-notification, that notice along with your
            identifying information included in the notice will be provided to
            the party that submitted the original claim of infringement.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Advertising Rights
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Company reserves the right to sell and display any advertising,
            attribution, links, promotional and distribution rights, and Company
            and its licensors or affiliates will be entitled to retain any and
            all revenue generated from any sales of such advertising,
            attribution, links, or promotional or distribution rights. Nothing
            in these additional terms obligates or may be deemed to obligate
            Company to sell or offer to sell any advertising, promotion or
            distribution rights.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Accuracy of Information
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            We attempt to ensure that information on the Site is complete,
            accurate and current. Despite our efforts, the information on the
            Site may occasionally be inaccurate, incomplete or out of date. We
            make no representation as to the completeness, accuracy or currency
            of any information on the Site.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Links
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Running or displaying the Site or any information or material
            displayed on the Site in frames or through similar means on another
            Site without our prior written permission is prohibited. From time
            to time, the Site may contain links to third-party websites that are
            not owned, operated or controlled by Company or its affiliates. All
            such links are provided solely as a convenience to you. If you use
            these links, you will leave the Site. Neither we nor any of our
            respective affiliates are responsible for any content, materials or
            other information located on or accessible from any other websites.
            Neither we nor any of our respective affiliates endorse, guarantee,
            or make any representations or warranties regarding any other
            websites, or any content, materials or other information located or
            accessible from any other websites, or the results that you may
            obtain from using any other websites. If you decide to access any
            other websites linked to or from the Site, you do so entirely at
            your own risk.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Inappropriate Submissions and Use of the Site
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You are prohibited from sending or posting any unlawful,
            threatening, defamatory, libelous, obscene, pornographic or profane
            messages or materials on the Site. You also are prohibited from
            posting Controlled Unclassified Information or Classified
            information on the Site. You may not post material on the Site, or
            otherwise use the Site in any manner, that could: (i) humiliate,
            threaten, or injure other people or their property rights,
            including, but not limited to, intellectual property rights; (ii)
            violate the privacy or publicity rights of other individuals or
            entities; (iii) be considered criminal conduct or give rise to civil
            liability; or (iv) otherwise violate any law or these Terms. You
            further understand and agree that posting unsolicited advertisements
            on the Site is expressly prohibited by these Terms. Any unauthorized
            use of our computer systems is a violation of these Terms. In
            addition to any remedies that we may have at law or in equity, if we
            determine, in our sole discretion, that you have violated or are
            likely to violate the foregoing prohibitions, we may take any action
            we deem necessary to prevent or cure the violation, including
            without limitation, the immediate removal of your content from the
            Site and termination of your access to the Site. As set forth in our{" "}
            <Link to={"/privacy-policy"} className="text-xl">
              Privacy Policy
            </Link>
            , we may fully cooperate with any law enforcement authorities or
            court order or subpoena requesting or directing us to disclose the
            identity of anyone posting such materials.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Account Registration and Security
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You understand that you will need to create an account to have
            access to parts of the Site. In consideration of your use of the
            Site, you will: (a) provide true, accurate, current and complete
            information about yourself as prompted by the Site&apos;s
            registration process; and (b) maintain and promptly update your
            information to keep it true, accurate, current and complete. If
            Company suspects that such information is untrue, inaccurate, not
            current or incomplete, Company has the right to suspend or terminate
            your account and refuse any and all use of the Site. You are
            entirely responsible for the security and confidentiality of your
            password and account. Furthermore, you are entirely responsible for
            any and all activities that occur under your account. You agree to
            immediately notify us of any unauthorized use of your account or any
            other breach of security of which you become aware. You are
            responsible for taking precautions and providing security measures
            best suited for your situation and intended use of the Site. Please
            note that anyone able to provide your username and password will be
            able to access your account so you should take reasonable steps
            (i.e., two-factor authentication) to protect this information.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Access and Interference
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You agree that you will not use any robot, spider, scraper or other
            automated means to access the Site for any purpose without our
            express written permission. Additionally, you agree that you will
            not: (i) take any action that imposes, or may impose in our sole
            discretion an unreasonable or disproportionately large load on our
            infrastructure; (ii) interfere or attempt to interfere with the
            proper working of the Site or any activities conducted on the Site;
            or (iii) bypass any measures we may use to prevent or restrict
            access to the Site.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Privacy
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            Information collection and use, including the collection and use of
            personal information, is governed by our{" "}
            <Link to={"/privacy-policy"} className="text-xl">
              Privacy Policy
            </Link>
            , which is incorporated into and is a part of these Terms.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Representations and Warranties
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You shall be solely responsible for your own User Content and the
            consequences of posting or publishing it. You affirm, represent and
            warrant the following: (i) you are over the age of 18 and have the
            right and authority to enter into this agreement, and are fully able
            and competent to satisfy the terms, conditions and obligations
            herein; (ii) you have obtained all consents, and possess all
            copyright, patent, trademark, trade secret and any other proprietary
            rights, or the necessary licenses thereto, for any content you’ve
            provided to the Site; (iii) if necessary you have the written
            consent of each and every identifiable natural person to use such
            person’s name or likeness in the manner contemplated by the Site and
            these Terms, and each such person has released you from any
            liability that may arise in relation to such use; (iv) you have
            read, understood, agree with, and will abide by the terms and
            conditions of this agreement; (v) you are not, and have not been an
            agent of Company and were not and are not acting on behalf of, or as
            a representative of, Company or any other party; and (vi) Company
            use of any information you have submitted as contemplated by the
            Terms and the Site will not infringe any rights of any third party,
            including but not limited to any intellectual property rights,
            privacy rights and rights of publicity ; (vii) you are not, nor is
            any other person who appears in your User Content, a current member
            of the Screen Actors Guild (SAG), the American Federation of
            Television and Radio Actors (AFTRA) or any other rights society;
            (viii) you have not and will not engage in any of the activities or
            behaviors listed in the Inappropriate User Submissions section in
            the production of, your appearance in, or contribution(s) to your
            User Content; (ix) the User Content does not contain: (a) material
            falsehoods or misrepresentations that could harm Company or any
            third party; (b) content that is unlawful, obscene, defamatory,
            libelous, threatening, pornographic, harassing or encourages conduct
            that would be considered a criminal offense, give rise to civil
            liability or violate any law; (c) advertisements or solicitations of
            business; or (d) impersonations of third parties, other than those
            which are readily apparent.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            DISCLAIMERS
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            YOUR USE OF THE SITE IS AT YOUR OWN RISK. THE INFORMATION,
            MATERIALS, AND SERVICES PROVIDED ON, THROUGH, OR IN CONNECTION WITH
            THE SITE OR OTHERWISE PROVIDED BY US ARE PROVIDED &ldquo;AS
            IS&rdquo; WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING, BUT NOT
            LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, SECURITY, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY,
            FREEDOM FROM COMPUTER VIRUS, OR WARRANTIES ARISING FROM COURSE OF
            DEALING OR COURSE OF PERFORMANCE. TO THE PERFORMANCE. TO THE FULLEST
            EXTENT PERMITTED BY APPLICABLE LAW, WE HEREBY DISCLAIM ALL DISCLAIM
            ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, WITH RESPECT
            TO THE SITE AND OUR SERVICES. WE DO NOT REPRESENT OR WARRANT THAT
            THE SITE OR ANY INFORMATION OR SERVICES WE PROVIDE WILL BE
            UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
            THE SITE IS FREE OF COMPONENTS. WE DO NOT MAKE ANY WARRANTIES OR
            REPRESENTATIONS REGARDING THE COMPLETENESS, CORRECTNESS, ACCURACY,
            ADEQUACY, USEFULNESS, TIMELINESS OR RELIABILITY OF THE SITE OR ANY
            INFORMATION OR SERVICES WE PROVIDE, OR ANY OTHER WARRANTY, THE
            INFORMATION, MATERIALS, AND AND SERVICES PROVIDED ON OR THROUGH THE
            SITE MAY BE OUT OF DATE, AND NEITHER COMPANY NOR ANY OF OF OF
            AFFILIATES MAKES ANY COMMITMENT OR ASSUMES ANY DUTY TO UPDATE SUCH
            INFORMATION, MATERIALS, SERVICES. THE FOREGOING EXCLUSIONS OF
            IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT PROHIBITED PROHIBITED
            BY APPLICABLE LAW.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            LIMITATIONS OF LIABILITY
          </TextTypography>

          <TextTypography className="font-normal mb-6 text-xl">
            COMPANY DOES NOT ASSUME ANY RESPONSIBILITY FOR AND WILL NOT BE
            LIABLE, FOR ANY DAMAGE TO OR ANY VIRUS THAT MAY INFECT YOUR
            COMPUTER, EQUIPMENT, OR OTHER PROPERTY CAUSED BY OR ARISING FROM
            YOUR ACCESS TO, USE OF, OR BROWSING THE SITE, OR YOUR DOWNLOADING OF
            OR ACCESSING ANY MATERIALS FROM THE SITE. IN NO EVENT WILL COMPANY,
            OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS,
            AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, NOR ANY PARTY INVOLVED IN
            THE CREATION, PRODUCTION OR TRANSMISSION OF THIS SITE, BE LIABLE TO
            YOU OR ANYONE ELSE FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL
            OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
            RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION)
            ARISING OUT OF THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF
            THIS SITE, ANY SITES LINKED TO THIS SITE, OR THE MATERIALS,
            INFORMATION, OR SERVICES CONTAINED ON OR PROVIDED IN CONNECTION WITH
            THE SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER
            LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. YOU SPECIFICALLY ACKNOWLEDGE THAT COMPANY SHALL NOT BE
            LIABLE FOR USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
            CONDUCT OF ANY THIRD PARTY, AND THAT THE RISK OF HARM OR DAMAGE FROM
            THE FOREGOING RESTS ENTIRELY WITH YOU. THE FOREGOING LIMITATIONS OF
            LIABILITY DO NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            IN THE EVENT OF ANY PROBLEM WITH THE SITE OR ANY CONTENT OR
            SERVICES, YOU AGREE THAT YOUR SOLE REMEDY IS TO CEASE USING THE SITE
            OR THE CONTENT OR SERVICES. IN NO EVENT SHALL COMPANY&apos;S TOTAL
            LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION
            WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO,
            NEGLIGENCE), OR OTHERWISE EXCEED THE GREATER OF (A) TWENTY FIVE
            DOLLARS (US $25.00) OR (B) THE VALUE OF YOUR SUBSCRIPTION TO OUR
            SERVICES FOR THE LAST SIX (6) MONTHS.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Indemnity
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You agree to defend, indemnify and hold Company, each of our parent
            companies, subsidiaries and affiliates and the successors of each of
            the foregoing, and the officers, directors, agents, and employees of
            each of the foregoing, harmless from any and all liabilities, costs,
            and expenses, including reasonable attorneys’ fees, related to or in
            connection with (i) your use of the Site or your placement or
            transmission of any message or information on the Site by you or
            your authorized users; (ii) your violation of any term or condition
            of these Terms, including without limitation, your breach of any of
            the representations and warranties; (iii) your violation of any
            third-party rights, including without limitation any right of
            privacy, publicity rights or intellectual property rights; (iv) your
            violation of any law, rule or regulation of the United States or any
            other country; (v) any claims or damages that arise as a result of
            any information or material that you provide to Company; or (vi) any
            other party’s access and use of the Site with your unique username,
            password or other appropriate security code. Release. In the event
            that you have a dispute with another user or other party related to
            the Site, you release Company (and our officers, directors, agents,
            subsidiaries, joint ventures and employees) from all claims, demands
            and damages (actual and consequential) of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in any way connected with such
            disputes.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            Termination
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            You or we may suspend or terminate your account or your use of the
            Site at any time, for any reason or for no reason. We may also block
            your access to our Site in the event that (a) you breach these
            Terms; (b) we are unable to verify or authenticate any information
            you provide to us; (c) we believe that your actions may cause
            financial loss or legal liability for our users or us; or (d) or as
            otherwise provided herein or in another incorporated policy. You
            understand that any termination of your account, by you or us, may
            involve the permanent deletion of your data. Company will not have
            any liability whatsoever to you for any suspension or termination,
            including for deletion of your data.
          </TextTypography>

          <TextTypography className="text-center font-bold mb-8  text-3xl sm:text-4xl">
            General
          </TextTypography>

          <TextTypography className="font-normal mb-6 text-xl">
            Any dispute or claim arising out of or relating to the use of the
            Site and the materials contained herein is governed by the laws of
            the District of Columbia, without regard to its choice of law
            provisions. You consent to the exclusive jurisdiction of the state
            and federal courts located in the District of Columbia. You hereby
            irrevocably consent to such venue and to the exclusive jurisdiction
            of any such court over any such dispute. Any and all disputes
            directly or indirectly arising out of or related to the Terms or the
            Site will be resolved individually, without resort to any form of
            class actions. Any and all claims, judgments and awards will be
            limited to actual out-of-pocket costs incurred, and in no event
            shall you be entitled to recovery of attorney’s fees. Under no
            circumstances will any claimant be permitted to obtain any award
            for, and you hereby waive all rights to claim punitive, special,
            incidental or consequential damages and any and all rights to have
            damages multiplied or otherwise increased and any other damages,
            other than for actual out-of-pocket expenses. Any cause of action or
            claim you may have directly or indirectly arising out of or related
            to the Terms or the Site must be commenced within one (1) year after
            the claim or cause of action arises. A printed version of these
            Terms will be admissible in judicial and administrative proceedings
            based upon or relating to these Terms to the same extent as other
            business documents originally generated and maintained in printed
            form. You shall comply with all applicable domestic and
            international laws, statutes, ordinances and regulations regarding
            your use of the Site.
          </TextTypography>

          <TextTypography className="font-normal mb-6 text-xl">
            We do not guarantee continuous, uninterrupted or secure access to
            our Site, and operation of the Site may be interfered with by
            numerous factors outside of our control. If any provision of these
            Terms is held to be invalid or unenforceable, such provision shall
            be struck and the remaining provisions shall be enforced. You agree
            that these Terms and all incorporated agreements may be assigned by
            Company in our sole discretion. Headings are for reference purposes
            only and in no way define, limit, construe or describe the scope or
            extent of such section. Our failure to act with respect to a breach
            by you or others does not waive our right to act with respect to
            subsequent or similar breaches. These Terms are the entire agreement
            between you and Company with respect to the subject matter hereof
            and supersede any prior understandings or agreements (written or
            oral). Any provision that must survive in order to give proper
            effect to its intent (e.g., indemnity, general, any perpetual
            license, limitations on liability, disclaimers, representations and
            warranties, etc.) shall survive the expiration or termination of
            these Terms.
          </TextTypography>

          <TextTypography className="font-normal mb-8 text-xl">
            All rights not granted herein are expressly reserved to Dome
            Compass, Inc.
          </TextTypography>
        </article>
      </section>
    </main>
  )
}

export default DomeTermsOfService
