import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import DomeTermsOfService from "../components/TermsOfService/TermsOfService"

const TermsOfService: React.FC = () => {
  return (
    <div className=" w-full bg-lightBackground">
      <FaviconHeader />
      <AppHeader />
      <DomeTermsOfService></DomeTermsOfService>
      <AppFooter />
    </div>
  )
}
export default TermsOfService
